.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #cdf0c0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.App-version {
  position: fixed;
  right: 1%;
  bottom: 1%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center-block {
  display: table;  /* Instead of display:block */
  margin-left: auto;
  margin-right: auto;
}

.normal-font-size {
  font-size: calc(8px + 2vmin);
}

.small-font-size {
  font-size: calc(10px + 1vmin);
}
